@import './common';
@import '../components/Condition/styles/mobile';

.bank-conditions-landing__section {
  padding: rem($andes-spacing-24) rem($andes-spacing-16) rem($andes-spacing-32);

  .bank-conditions-landing__title {
    padding: 0 0 rem($andes-spacing-16);
  }

  .bank-conditions-landing__card {
    margin-bottom: rem($andes-spacing-40);
    padding: rem($andes-spacing-16) 0;
  }
}
