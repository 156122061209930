@import './site-shopping-info';

.site-shopping-info {
  padding-bottom: rem($andes-spacing-40);

  .andes-carousel-snapped__slide {
    justify-content: center;
  }

  .andes-carousel-snapped__pagination li {
    margin: 0 rem($andes-spacing-8);
  }

  .info-slide {
    margin-bottom: rem($andes-spacing-24);
  }
}
