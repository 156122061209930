@import 'commons';

.contact-info {
  width: 100%;
}

.contact-info__col-data {
  padding: (6 * $mdu) (3 * $mdu);
  width: 100%;
}

// Map styles
.ui-map-contain--fit {
  height: 40 * $mdu;

  & .ui-map__full {
    border-radius: 0 !important;
  }
}
