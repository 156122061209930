@import 'core';

.ui-ms-condition-container {
  margin: rem($andes-spacing-4) rem($andes-spacing-16) rem($andes-spacing-4);
  padding: rem($andes-spacing-4) rem($andes-spacing-16) rem($andes-spacing-4);

  .condition-short-info {
    font-size: rem($font-size-14);
    font-weight: $font-weight-semibold;
    margin-top: rem($andes-spacing-12);

    .condition-sub-info {
      margin-bottom: rem($andes-spacing-4);
    }

    .condition-name::after {
      border-bottom: 1px solid $payment-border-color;
      content: '';
      display: block;
      margin: rem($andes-spacing-24) 0;
      width: rem(32px);
    }
  }

  .condition-more-info .condition-title {
    font-size: rem($font-size-20);
  }
}
