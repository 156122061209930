@import './core';

.how-buy-landing__title-section {
  max-width: calc(100% - #{($andes-spacing-32)});
  padding-top: rem($andes-spacing-32);

  .title-section__text {
    margin-top: rem(20px);
  }
}
