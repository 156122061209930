.not-found-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem($andes-spacing-40 $andes-spacing-20 60px $andes-spacing-20);
}

.not-found-page__img {
  height: rem(203px);
}

.not-found-page__texts {
  text-align: center;
}

.not-found-page__title {
  color: $andes-text-color-disabled;
  font-size: rem(40px);
  font-weight: $font-weight-bold;
  letter-spacing: rem(0.8px);
  line-height: rem(40px);
  margin-bottom: rem($andes-spacing-4);
}

.not-found-page__subtitle {
  color: $andes-text-color-primary;
  font-size: rem($font-size-18);
  font-weight: $font-weight-bold;
  letter-spacing: rem(0.36px);
  line-height: rem(40px);
  margin-bottom: rem($andes-spacing-4);
}

.not-found-page__link {
  color: $andes-accent-color;
  font-size: rem($font-size-12);
  font-weight: $font-weight-bold;
  line-height: rem(40px);
  text-decoration: underline !important;
}
