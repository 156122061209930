@import './core';

.lgpd-landing__title-section {
  margin-top: ($mdu * 7);
  max-width: calc(100% - #{(4 * $mdu)});

  .title-section__icon {
    padding: ($mdu * 5) 0 ($mdu * 6.25) 0;
  }

  .title-section__text {
    margin-top: ($mdu * 4);
  }
}
