@import 'core';

.message-container {
  align-items: center;
  display: flex;
  padding: rem($andes-spacing-16) rem($andes-spacing-24);
  width: auto;

  .message-container__text {
    display: flex;
  }

  img {
    display: flex;
  }
}
