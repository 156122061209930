@import './common';

.cancel-buy-landing__section {
  padding: rem($andes-spacing-24) rem($andes-spacing-20) rem(72px);

  .cancel-buy-landing__card {
    padding: rem($andes-spacing-20);

    .cancel-buy-landing__content {
      p {
        font-weight: $font-weight-light;
      }
    }
  }
}
