.payment-data {
  @import 'payment-data';
}

.payment-data {
  .container {
    margin: 0;
    padding: 0 0 32px;
  }

  .payment-data {
    &-container {
      border-radius: rem($border-radius-6);
      text-decoration: none;
      width: 95%;
    }

    &-title {
      color: #333;
      font-size: 16px;
    }

    &-issuer {
      margin-top: $andes-spacing-20;
    }

    &-group-promo {
      justify-content: center;
    }

    &-section {
      height: auto;

      &-normal {
        flex: 0 1 auto;
        padding: rem($andes-spacing-20);
      }

      &-promo {
        text-align: center;
        padding: rem(15px) 0 rem(15px);
        width: 100%;

        &-logo {
          margin-top: -40px;
          flex: 0 1 auto;

          .payments-medium {
            margin-top: rem(10px);
          }
        }
      }
    }
  }
}
