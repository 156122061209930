@import 'commons';

.contact-form {
  margin-bottom: 16px;
  padding: 48px 24px;
  width: 100%;
}

.contact-form__input--email {
  width: 100%;
}
