@import './core';

.how-buy-landing__steps {
  max-width: calc(100% - #{($andes-spacing-32)});
  margin: 0 auto;
  padding-bottom: rem($andes-spacing-32);

  .steps__step {
    padding: rem($andes-spacing-24) 0;
  }

  .step__title {
    padding: 0 0 rem($andes-spacing-12) 0;
  }
}
