@import './core';

.protected-purchase__info-section .protected-purchase__info-container {
  padding: rem($andes-spacing-32) rem($andes-spacing-20) rem($andes-spacing-64);

  .title-section__link {
    display: block;
    max-width: $protected-purchase-content-max-width-mobile;
  }
}
