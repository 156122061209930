@import './desktop';

.buyer-info {
  .container {
    flex-direction: column;
    margin: 0;

    .buyer-info__card {
      margin: 0 rem($andes-spacing-8);
    }

    :last-child.buyer-info__card {
      margin: rem($andes-spacing-8) rem($andes-spacing-8) rem($andes-spacing-32);
    }

    a,
    p {
      text-align: center;
    }
  }
}
